import React from 'react'
import { useHistory } from 'react-router-dom'
import SidebarStudent from '../../components/SidebarStudent/SidebarStudent'
import '../StudentLayout/studentlayout.css'

function StartAssesment() {
    let history = useHistory();
  return (
    <div className = "container_student">
    <SidebarStudent/>     
<div className = "profilelayout_content-s">
    {/* <img className = 'logo'src = {logo} width="300px"/>      */}
    <p style ={{marginTop:'1em', paddingLeft:'2em', paddingRight:'2em'}}><strong>Instructions: </strong>Kindly read and answer each question carefully. Upon passing the assesment, the certificates will be sent to your email address. <br/><br/> Don't rush to answer, have patience and test what you have learned throughout your xcryptaces journey. <br/><br/> Passing Score is 75, failure to pass will disable the assessment form for 2 weeks. Goodluck.</p>
    <p style ={{marginTop:'1em', paddingLeft:'2em', paddingRight:'2em'}}><i>If you are ready just click the button below.</i></p>
    <button style = {{
       color: 'white',
       fontSize: '14px',
       borderRadius: '5px',
       fontWeight: '500',
       padding: '.5em',
       marginLeft:'2em',
       marginRight:'2em',
       marginTop: '15px',
       marginBottom: '10px',
       backgroundColor: '#20AFFF',
       cursor: 'pointer',
       border:'none'
    }}onClick={()=> history.push('/assesment/start')}>Start Assesment</button>

    <p style ={{marginTop:'10px', paddingLeft:'2em'}}>Prepared by: Coach Anthony Louis Corpuz
</p>
</div>
</div>
  )
}

export default StartAssesment