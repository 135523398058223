
import './appbar.css'
import { Link } from 'react-router-dom';
import { BiMenuAltLeft } from 'react-icons/bi';
import Modal from 'react-modal';
import Login from '../Login/login';
import React, { useState } from 'react'

const customStyles = {
    content : {
  
        top                   : '40%',
        left                  : '50%',
        right                 : 'auto',
        // position                :'fixed',
        bottom                : 'auto',
   
        transform             : 'translate(-50%, -50%)',
        backgroundColor   : '#f0f0f0'
      
    }
};
const Appbar3 = ({handleSidebar}) =>{
    const [login, setLogin] = useState(false)

    const [modalIsOpen,setModalIsOpen] = useState(false);

    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
    }
    
    return(
        <div className = "appbar">
            <div className = "appbar_wrapper">
                <div className="appbar_logo">
                </div>
                <div className='appbar_menu'>
                    <ul>
                      
                        <li > <Link to = '/'>Home</Link></li>
                        <li ><Link to = '/freewebinar'>Free Webinar</Link></li>
                
                        <li ><Link to = '/masterclass/firstbatch'>Masterclass</Link></li>
                        <li className='isSelected'><Link to = '/nft/whitelist'>NFT Whitelist</Link></li>
                   
                        <li className = 'login_lnk' onClick={setModalIsOpenToTrue}>Login</li>
                        
                    </ul>
                    <Modal isOpen={modalIsOpen} ariaHideApp={false} style={customStyles} onRequestClose={()=> setModalIsOpen(false)} >
                                <Login/>
                            </Modal>

                </div>
                    <div className="appbar_avt">
                        <BiMenuAltLeft onClick={handleSidebar}/>
                    </div>
            </div>
        </div>
    );
}
export default Appbar3;
