import React, { Component } from 'react'
import Appbar2 from '../Appbar/Appbar2'

import './masterauth.css'

import pic1 from '../../images/pic1.png'
import pic2 from '../../images/pic2.png'
import pic3 from '../../images/pic3.png'
import pic4 from '../../images/pic4.png'
import pic5 from '../../images/pic5.png'
import pic6 from '../../images/pic6.png'
import t1 from '../../images/testimonial.jpg'
import "bootstrap/dist/css/bootstrap.min.css"
import t2 from '../../images/testimoniall.jpg'
import about_img from '../../images/paroparo.png'
import Carousel from 'react-bootstrap/Carousel'
import {Footer} from '../Footer/Footer';
import SidebarAuth from '../Sidebar/SidebarAuth';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import img from '../../images/wb.jpg';
import MessengerCustomerChat from 'react-messenger-customer-chat';

export class MasterAuth extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sidebar:false,
            showmore:false
        }
    }
    handleSidebar=() =>{
        this.setState({
            sidebar: !this.state.sidebar
        });
    }
    handleShowMore=() =>{
        this.setState({
            showmore: !this.state.showmore
        });
    }

    render() {
        return (
            <div className='main-container-master'>
                          <MessengerCustomerChat
        pageId="104959258728460"
        appId="2837583293208170"
        minimized="true"
    />
              <Appbar2 handleSidebar={this.handleSidebar}/>

                <div className={this.state.sidebar ? "home_sidebar open" : "home_sidebar"}>
                <SidebarAuth />
                </div>
               
               <div className='container-masterclass' >

               <div className='about-container'>
                    <div className='title_section'>
                    <h1>Harmonics Trading Masterclass</h1>
                    <h2>Acquire the exact trading strategies, and system that made traders knowledgeable in the market and generate more profit.</h2>   
                </div>
                        
                    <section className='about_section'>
                        <div className='image_container'>
                            <img src = {about_img}/>
                            <button onClick={() => this.props.history.push('/masterclass/payment')} disabled>Batch 4 Coming soon</button>
                         
                        </div>

                        <div className='masterclass_description_container'>
                        <h3>You will be able to learn:</h3>       
                            <ol>
                                <li>The cryptocurrency market behavior by charting.</li>
                                <li> The basic patterns and how you will use them in your trading strategy.</li>
                                <li>How to use the price action in your trading strategy (Fibonacci Trading).</li>
                                <li>How to use the advance pattern and execute them well (Harmonics).</li>
                                <li>How to control emotion when trading (Risk Management).</li>
                            </ol>
                            <br/>
                          
                           <h4>
                            This is a LIFETIME ACCESS for you to have a complete guide along with your development and transformation as an independent harmonics trader.
                            </h4>
                            <br/>
                        
                            <h3>What you can get?</h3>
                            <ol>
                                <li>Unlimited access to the recordings.</li>
                                <li>Be included in our exclusive telegram group.</li>
                                <li>Unlimited support and assistance.</li>
                                <li>Certificate of completion.</li>
                                <li>Be included in my team as long as you are actively trading.</li>
                            </ol>
                          

                            
                        </div>
                        
                  
                        
                        

                    </section>
                    
                        <div style={{padding:'30px'}}>
                            <hr/>
                        <p>DISCLAIMER</p>
                            <p>Important Note:</p>
                            <p><i>Xcrypt Aces online learning materials DOES NOT make any guarantee that you will have instant result and earnings with your skills and knowledge. Nothing on this website or any of our platform with provided contents will guarantee future earnings or result. Always use caution and consult your professional advisor, consultants and lawyer before acting on this. By your registration here you agree not to attempt to hold us liable for your result, decision, or action under any circumstances in any time. You alone are always accountable and responsible for your result of action and decision.</i></p>
                            <p><i>This site is not a part of the Facebook website or Facebook Inc. Additionally, This site is NOT endorsed by Facebook in any way. FACEBOOK is a trademark of FACEBOOK, Inc.</i></p>
                        </div>
                           
                </div>
              
               <Carousel fade="true" data-interval ="1000" style = {{width: "100%",position:'sticky', marginLeft:'.5em'}} >
                    <Carousel.Item id='testimonial'>
                        <img alt="first" className='="d-block w-100' src={pic1} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic2}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic3}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic4}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic5}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic6}/>
                    </Carousel.Item>
                </Carousel>

                </div>


                <Carousel fade="true" data-interval ="1000" style={{position:'sticky'}}>
                    <Carousel.Item id='testimonial'>
                        <img alt="first" className='="d-block w-100' src={t1} style = {{height: "100%"}} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={t2}/>
                    </Carousel.Item>
                </Carousel>

                <img src = {img}/>
                <Footer/>
            </div>
        )
    }
}

export default MasterAuth
