import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as IoIcons from 'react-icons/io'
import React from 'react';


const id = ['61c60c7eae1254d48214bdb9',"61c60de4ae1254d48214bdbc",
"61c60efdae1254d48214bdc0","61c60f2cae1254d48214bdc3","61c610c4ae1254d48214bdc8",
"61c610dcae1254d48214bdcb","61c724559f6b255f938565a5","61c725719f6b255f938565bb"
,"61c72af69f6b255f938565d1","61c72b709f6b255f938565dd",
"61cad0d57ab595f03c19e64d","61cb21777ab595f03c19e65a","61cb220b7ab595f03c19e65f",
'61cb22537ab595f03c19e666','61cb22b47ab595f03c19e671','61cb22d07ab595f03c19e679',
'61cb25957ab595f03c19e68d', '61cb25a87ab595f03c19e692', '61cb25ed7ab595f03c19e697',
'61cb28037ab595f03c19e69c', '61cb281b7ab595f03c19e6a1', '61cb282a7ab595f03c19e6a6',
'61cb28737ab595f03c19e6ab','61cb28877ab595f03c19e6b0','61cb289c7ab595f03c19e6b5', 
'61cb28ae7ab595f03c19e6ba','61cb292b7ab595f03c19e6bf','61cb293c7ab595f03c19e6c4',
'61cb2a637ab595f03c19e6c9','61cb2a7e7ab595f03c19e6ce',"61cf607548b8b732fa7b4ca3",
"62165f4a78a7b4c7b7dfcdb4","62165fad78a7b4c7b7dfcdb5","6221103175d76cc968a54d62",
"622110a275d76cc968a54d63", "622110a775d76cc968a54d64","622110ac75d76cc968a54d65"]

const lesson = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37]


export const SidebarDataStudent = [
 
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Part 1',
    path:'#',
    icon: <RiIcons.RiBook2Fill />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Introduction: Market Structure',
        path: `/watch/${id[0]}/${lesson[0]}`
      },
      {
        title: 'Raw Market Move',
        path: `/watch/${id[1]}/${lesson[1]}`
      },
      {
        title: 'Recognizing S/R',
        path: `/watch/${id[2]}/${lesson[2]}`
      },
      {
        title: 'Break of trend Continuation',
        path: `/watch/${id[3]}/${lesson[3]}`
      },
      {
        title: 'Start End of trend: EMAs (55,24, and 8 )',
        path: `/watch/${id[4]}/${lesson[4]}`
      },
      {
        title: 'Market Movement: Different Phases',
        path: `/watch/${id[5]}/${lesson[5]}`
      },
    ]
  },
  {
    title: 'Part 2',
    path: '#',
    icon: <RiIcons.RiBook2Fill />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Trendline',
        path: `/watch/${id[6]}/${lesson[6]}`
      
      },
      {
        title: 'Countertrendline',
        path: `/watch/${id[7]}/${lesson[7]}`
      },
      {
        title: 'Double Top',
        path: `/watch/${id[8]}/${lesson[8]}`
      },
      {
        title: 'Triangle and flags',
        path: `/watch/${id[9]}/${lesson[9]}`
      },
      {
        title: 'Head and Shoulder',
        path: `/watch/${id[10]}/${lesson[10]}`
      },
      
    ]
  },
  {
    title: 'Part 3',
    path: '#',
    icon: <RiIcons.RiBook2Fill />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Fibonacci',
        path: `/watch/${id[11]}/${lesson[11]}`
      
      },
      {
        title: 'Fibonacci Extension',
        path: `/watch/${id[12]}/${lesson[12]}`,
      },
      {
        title: 'Candlestick Analysis',
        path: `/watch/${id[13]}/${lesson[13]}`,
      },
    ]
  },
  {
    title: 'Part 4',
    path: '#',
    icon: <RiIcons.RiBook2Fill />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Gartley - Part 1',
        path: `/watch/${id[14]}/${lesson[14]}`,
      
      },
      {
        title: 'Gartley - Part 2',
        path: `/watch/${id[15]}/${lesson[15]}`,
      },
      {
        title: 'Bat Pattern - Part 1',
        path: `/watch/${id[16]}/${lesson[16]}`,
      },
      {
        title: 'Bat Pattern - Part 2',
        path: `/watch/${id[17]}/${lesson[17]}`,
      },
      {
        title: 'Bat Pattern - Part 3',
        path: `/watch/${id[18]}/${lesson[18]}`,
      },
      {
        title: 'Butterfly - Part 1',
        path: `/watch/${id[19]}/${lesson[19]}`,
      },
      {
        title: 'Butterfly - Part 2',
        path: `/watch/${id[20]}/${lesson[20]}`,
      },
      {
        title: 'Cypher - Part 1',
        path: `/watch/${id[21]}/${lesson[21]}`,
      },
      {
        title: 'Cypher - Part 2',
        path: `/watch/${id[22]}/${lesson[22]}`,
      },
      {
        title: 'Shark Pattern - Part 1',
        path: `/watch/${id[23]}/${lesson[23]}`,
      },
      {
        title: 'Shark Pattern - Part 2',
        path: `/watch/${id[24]}/${lesson[24]}`,
      },
      {
        title: 'Crab Pattern - Part 1',
        path: `/watch/${id[25]}/${lesson[25]}`,
      },
      {
        title: 'Crab Pattern - Part 2',
        path: `/watch/${id[26]}/${lesson[26]}`,
      },
      {
        title: 'Deep Crab Pattern - Part 1',
        path: `/watch/${id[27]}/${lesson[27]}`,
      },
      {
        title: 'Deep Crab Pattern - Part 2',
        path: `/watch/${id[28]}/${lesson[28]}`,
      }
    ]
  },
  {
    title: 'Part 5',
    path: '#',
    icon: <RiIcons.RiBook2Fill />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Risk Management',
        path: `/watch/${id[29]}/${lesson[29]}`,
      
      },
      {
        title: '3 Drive Patterns',
        path: `/watch/${id[30]}/${lesson[30]}`,
      }, 
      {
        title: '5-0 Pattern',
        path: `/watch/${id[31]}/${lesson[31]}`,
      }, 
      {
        title: 'SMC part 1',
        path: `/watch/${id[32]}/${lesson[32]}`,
      }, 
      {
        title: 'SMC x harmonics part 2',
        path: `/watch/${id[33]}/${lesson[33]}`,
      }, 
      {
        title: 'Quarters Theory Part 1',
        path: `/watch/${id[34]}/${lesson[34]}`,
      }, 
      {
        title: 'QT x Harmonics Part 2',
        path: `/watch/${id[35]}/${lesson[35]}`,
      }, 
      {
        title: 'CLosing Video',
        path: `/watch/${id[36]}/${lesson[36]}`,
      }, 
    ]
  },
  {
    title: 'Logout',
    path:'/logout',
    icon: <IoIcons.IoMdLogOut />
  },
 
];
