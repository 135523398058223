import React, { useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import logo from '../../images/logo.png'
import SidebarStudent from '../../components/SidebarStudent/SidebarStudent'
function AssesmentLayout() {
    useEffect(() => {
        document.querySelector("iframe").addEventListener("load", 
        function() {
            window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
        });
        });
        
      }, []);
    return(
        <>
        <ToastContainer />
           <div className = "container_student">
              <SidebarStudent/>     
          <div className = "profilelayout_content-s">
              {/* <img className = 'logo'src = {logo} />      */}
                <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdwGyTvGAbNBGZeenJcEpca8E_vNr_L9ydJt1geWRTKk35Rhw/viewform?embedded=true" width="100%" height="30107" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                
          </div>
      </div>
      </>
     
    );
  
}

        
 


export default AssesmentLayout