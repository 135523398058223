import { createContext, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITITAL_STATE = {
    user: [],
    isLoggedIn: false,
    token:"", 
    studdata: [],
}

export const AuthContext = createContext(INITITAL_STATE)

export const AuthContextProvider = ({children}) =>{
    const [state, dispatch] = useReducer(AuthReducer, INITITAL_STATE)

    return(
        <AuthContext.Provider
            value = {{
                user: state.user,
                isLoggedIn: state.isLoggedIn,
                token:state.token,
                studdata: state.studdata,
                dispatch,
            }}
        >
            {children}
        </AuthContext.Provider>
    )

}