import React, { Component } from 'react'
import {FaTelegramPlane,FaTiktok, FaFacebook, FaDiscord} from 'react-icons/fa'
import './footer.css'
import logo from '../../images/logo.png'
import { Link } from 'react-router-dom';

export class FooterSocialMedia extends Component {
    render() {
        return (
            <div className='footer-social'>
                {/* <a href ="https://t.me/xcrypttraderaces" target={'_blank'}><FaTelegramPlane/></a>
                <a href ="https://vt.tiktok.com/ZSemCv4cg/" target={'_blank'}><FaTiktok/></a>
                <a href ="https://www.facebook.com/anthonycorpuz.xcryptacestrader" target={'_blank'}><FaFacebook/></a>
                <a href ="https://www.instagram.com/anthonylouiscorpuz/" target={'_blank'}><FaInstagram/></a> */}
            </div>
        )
    }
}

export class Footer extends Component {
    render() {
        return (
            <div className='footer'>
                <div className='footer-option'>
                <a href = "/privacypolicy">Privacy Policy</a>
                <Link to = '/termsandconditions'>Terms and Conditions</Link>
                <Link to = '/FAQs'>FAQs</Link>
                </div>
                <p><strong>Mail us on: </strong><span style={{cursor:'pointer'}}><u onClick={() => window.location = 'mailto:yourmail@gmail.com'}>xcryptacesacademy@gmail.com</u></span></p>
                
                <div className='footer-ic'>
                {/* <p>Follow us on: </p> */}
                <a href ="https://t.me/xcrypttraderaces" target={'_blank'}>Telegram <FaTelegramPlane/></a>
                <a href ="https://vt.tiktok.com/ZSemCv4cg/" target={'_blank'}>Tiktok <FaTiktok/></a>
                <a href ="https://www.facebook.com/anthonycorpuz.xcryptacestrader" target={'_blank'}>Facebook <FaFacebook/></a>
                <a href ="https://discord.gg/FVmpePdUGc" target={'_blank'}>Discord <FaDiscord/></a>
                </div>
            </div>
        )
    }
}

export default {FooterSocialMedia,Footer}
