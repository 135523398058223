import axios from 'axios';
import React, { Component } from 'react'
import Sidebar from '../../components/Sidebar/Sidebar';
import { AuthContext } from '../../context/AuthContext'
import SidebarStudent from '../../components/SidebarStudent/SidebarStudent';
import './studentlayout.css'


export class NoviceLessons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            lesson_data:[],
            user_role:'',
            selectedIndex: parseInt(this.props.match.params.lesson)
        }
        this._TogglePrev = this._TogglePrev.bind(this);
        this._ToggleNext = this._ToggleNext.bind(this);
    }

    componentDidMount() {
        this.getCourse();

        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
          });
    } 

    getCourse() {
        const user = this.context


        axios.get(`/api/courses/lesson-data-novice`).then(res => {
            if (res.data.success) {
                this.setState({
                    courses: res.data.courses,
                })
               
            }

            this.setState({
                user_role: user.user.role
            })
        })
    }
    static contextType = AuthContext

    _ToggleNext(id, index) {
        if(this.state.selectedIndex === this.state.courses.length - 1)
  
            return;

        this.setState(prevState => ({
            selectedIndex: prevState.selectedIndex + 1
        }))
        index = index+ 1
        this.props.history.push(`/watch/novice/${id}/${index}`)
    }

    _TogglePrev(id, index) {
        if(this.state.selectedIndex === 0)
         return;

        this.setState(prevState => ({
            selectedIndex: prevState.selectedIndex - 1
        }))
        index = index-1
        this.props.history.push(`/watch/novice/${id}/${index}`)
    }
   
    render() {
        //const id = this.props.match.params.id
        
        return (
            
            <div className='container_student_novice'>
                {this.state.user_role === 'admin'?<Sidebar/> : <SidebarStudent/>}
                

                    {this.state.courses.map((cours, index) => {
                       
                        return (
                            index === this.state.selectedIndex ?
                           
                           <div className="lesson_div-show-option" >  
                                    <p>    
                                    <span> 
                                        {
                                            index === 0 ? '':<button className="toggle toggle--prev" onClick={() => this._TogglePrev(cours._id, index)}>Previous
                                            </button>
                                        }
                                        
                                    </span>
                                    
                                    {cours.title}
                                

                                    <span>
                                       {index ===this.state.courses.length - 1 ? '' :  <button className="toggle toggle--next" onClick={() => this._ToggleNext(cours._id, index)}>Next
                                        </button> }  
                                    </span>
                                    
                                    </p>
                                    <hr/>
                                    <iframe src={cours.resource_url}allowFullScreen="true" sandbox="allow-same-origin allow-scripts" ></iframe>
                                   
                                 <footer>
                                 <h6><i>Any unauthorized redistribution, copying, or publication by you of any such Content will be punishable by law according to RA No. 8293; which may result to either/or and is not limited to: fine of Php 50,000 to Php 1.5 Million and/or imprisonment of 1 to 9 years</i></h6>
                            
                                </footer>
                                
                                </div>
                            :''
                        )
                    })}
            
            </div>
        )
    }
}

export default NoviceLessons
