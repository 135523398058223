import React, { Component } from 'react'
import './studentlayout.css'
import logo from '../../images/logo.png'
import axios from 'axios'

import SidebarStudent from '../../components/SidebarStudent/SidebarStudent' 
import { AuthContext } from '../../context/AuthContext'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from '../../components/Profile/Profile'
import Navbar from './studentlayoututils/Navbar'
import Home from './studentlayoututils/Home'



const StudentLayout = () => {
  return (
    <div className='container_student'>
      <SidebarStudent/> 
      <Home/>
    </div>
  )
}

export default StudentLayout

