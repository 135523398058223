import './activatelayout.css'
import logo from '../../images/logo.png'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios'

import React, { Component } from 'react'

export class Activatelayout extends Component {

    constructor(props) {
        super(props);
    }

    handleClick = () =>{
        const activation_token = this.props.match.params.activation_token
        try {
            const data = {
                activation_token
            }
            const res = axios.post("/api/auth/activation", data);
            alert("Welcome your account has been succesfully activated")
            this.props.history.push('/')
          } catch (err) {
            toast(err);
          }
    }
    render() {
  
        return (
            <div className='activate'>
            <img src ={logo}/>
            <p onClick={this.handleClick}>Verify Email</p>

        </div>
        )
    }
}

export default Activatelayout

