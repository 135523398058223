import "./authlayout.css"
import { useState } from "react";
import HomeAuth from "../../components/HomeAuth/HomeAuth";

const AuthLayout = () =>{

    const [login,setLogin] = useState(true)
    const [register,setRegister] = useState(false)
    const [forgot,setForgot] = useState(false)

    const handleLogin = () =>{
        setLogin(true);
        setRegister(false)
        setForgot(false)
    }

    const handleRegister = () =>{
        setLogin(false);
        setRegister(true)
        setForgot(false)
    }

    const handleForgot = () =>{
        setLogin(false);
        setRegister(false)
        setForgot(true)
    }

    return(
        <div className = "authlayout">
            <HomeAuth/>
            {/* {login && <Login/>} */}
        </div>
    );
}
export default AuthLayout;
