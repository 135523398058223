const AuthReducer = (state, action) => {
    switch (action.type) {
        case "SIGNGING":
            return {
                ...state,
                user: [{role: action.payload}],
                isLoggedIn: true,
            }

        case "GET_ROLES":
            return{
                ...state,
                roles: [{roles: action.payload}]
            }
        case "GET_STUD_USERS":{
            return {
                ...state,
                studdata: action.payload,
            }
        }
        case "GET_TOKEN":
            return {
                ...state,
                token: action.payload,
            }

        case "GET_USER":
            return {
                ...state,
                user: action.payload,
            }
        case "UPDATE_AVATAR":
            return {
                ...state,
                user: [{ avatar: action.payload }],
            }
        case "SIGNOUT":
            return {
                ...state,
                isLoggedIn: false,
                token: '',
                user: []
            }
        default:
            return state;
    }
}

export default AuthReducer