import React, {useState} from 'react'
import Appbar from '../Appbar/Appbar'
import SidebarAuth from '../Sidebar/SidebarAuth'
import { ToastContainer, toast } from "react-toastify";
import {Footer} from '../Footer/Footer';
import './footer-option.css'


function PrivacyPolicy() {

    const [sidebar, setSidebar] = useState(false)
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }
  return (
    <>
        <ToastContainer/>
        <div className='footer-options'>
            <Appbar handleSidebar={handleSidebar}/>

            <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
            <SidebarAuth />
            </div>


            
            <h6>Privacy Policy
</h6>
            <p>
            Any personal information that you provide to us is privacy protected. 

Your information will never be shared or sold to anyone. 

Member’s personal information is stored on secure servers. 

We make every effort to ensure this information remains uncompromised.


            </p>


       

            
            <Footer/>
        </div>
        </>
  )
}

export default PrivacyPolicy