import React, {useState} from 'react'
import Appbar from '../Appbar/Appbar'
import SidebarAuth from '../Sidebar/SidebarAuth'
import { ToastContainer, toast } from "react-toastify";
import {Footer} from '../Footer/Footer';

function Faqs() {

    const [sidebar, setSidebar] = useState(false)
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }

    return (
               <>
        <ToastContainer/>
        <div className='footer-options'>
            <Appbar handleSidebar={handleSidebar}/>

            <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
            <SidebarAuth />
            </div>


            <h6>FAQs</h6>
            <p>
            <strong>Q: </strong>
            "Coach paano po iyun wala po ako natanggap na email
sa spam message ko?"
<br/>
<strong>A: </strong>

If sa website mo ginawa ang pag settle ng account payment mo, 
then you will receive an email automatically.
Buf if not, and you manually settle your account sa FB page for the
enrollment, then kindly wait for the email.
Aayusin po yan ni web dev if manual
payment at gagawan ka niya ng dashboard. 

If still sa website ka nag-enroll and wala kang natanggap na email
then mag message ka lang sa FB page ko para ma-assist ka and also to
help you with your concerns.

<br/>
<br/>
<strong>Q: </strong>
"Coach Beginner friendly po ba ito?"
<br/>
<strong>A: </strong>
Good news, yes beginner friendly, 
kahit 0 knowledge ka you can attend 
remedial classes multiple times
<br/>
<br/>
<strong>Q: </strong>
"gaano katagal ang mentoring?"
"pano yung mentoring sir?"
"may live sessions po ba?"
<br/>
<strong>A: </strong>
Ang mentorirship ay one time payment LIFETIME ACCESS to recordings, UNLI sit-ins
sa mga live discussion, at LIFETIME membership sa FB support group at
private telegram chat group
<br/><br/>
<strong>Q: </strong>
"Ano po ung schedule ng classes?"
<br/>
<strong>A: </strong>
Good news is wala tayong fix schedule sa class, kasi magkakaroon ka ng
dashboard na kung saan pwede mo itong iaccess anytime and anywhere, so
kahit may trabaho ka or busy sa araw araw, pwede kang mag aral sa free time mo

<br/><br/>
<strong>Q: </strong>
"Can I pay sa gcash?"
<br/>
<strong>A: </strong>
Gcash is temporary not available, but you still can use 
your gcash to transfer money to other ewallets and 
bank na available 
<br/><br/>
<strong>Q: </strong>
"After payment ano na pong susunod na gagawin ko coach?"
<br/>
<strong>A: </strong>
Provide mo ung mga sumusunod and isend sa aking FB page:
(do not provide na if directly ka sa website nag register and enroll)
name:
contact no:
email address(gmail account):

After providing your details I'll invite you to 
the private Elites TG group of Xcrypt Aces just send 
to my FB page your TG username and also receipt as your pass, 
before approving your request to join.
<br/>
<br/>
<strong>Q: </strong>
"Coach may SMC po ba?"
<br/>
<strong>A: </strong>

Yes, included ang SMC sa ating mentorship program at ituturo itong isang
new secret strategy ng mga big institutions.



            </p>

       

            
           <Footer/>
        </div>
        
        </>

    )
}

export default Faqs
