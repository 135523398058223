import React, { useState, useEffect } from 'react'
import Appbar from '../Appbar/Appbar'
import "./homeauth.css"
import vidfree from '../../images/vid.mp4'
import { useHistory } from 'react-router-dom';
import logo from '../../images/logo.png'
import t1 from '../../images/testimonial.jpg'
import "bootstrap/dist/css/bootstrap.min.css"
import t2 from '../../images/testimoniall.jpg'
import Carousel from 'react-bootstrap/Carousel'
import {Footer} from '../Footer/Footer';
import SidebarAuth from '../Sidebar/SidebarAuth';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css"
import Aos from 'aos'
import desc_ic from '../../images/stock.png';
import desc_ic2 from '../../images/global-online.png';
import desc_ic3 from '../../images/trade.png';
import pic1 from '../../images/pic1.png'
import pic2 from '../../images/pic2.png'
import pic3 from '../../images/pic3.png'
import pic4 from '../../images/pic4.png'
import pic5 from '../../images/pic5.png'
import pic6 from '../../images/pic6.png'
import video_bg from '../../images/video_bg.mp4'
import MessengerCustomerChat from 'react-messenger-customer-chat';

const HomeAuth =()  => {

    let history = useHistory();
    const [sidebar, setSidebar] = useState(false)
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }
        
return (
    <> 
      <ToastContainer />
      
        <div className='main-container'>
        <MessengerCustomerChat pageId="104959258728460" appId="2837583293208170" minimized="true"/>
        
        <Appbar handleSidebar={handleSidebar}/>
        
        <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
            <SidebarAuth />
        </div>
             
        <div className='content-container' >
            {/* <video style ={{opacity:'.2'}} className="background-video" autoPlay loop muted src={video_bg} type="video/mp4">
            </video> */}
        
        <section className='intro_section'>
            <img src = {logo} alt="logo"/>
            <h1>Xcrypt Aces</h1>
          
            <p>Learn Harmonics Trading that made traders become more profitable and confident to stay in the market longer through advance trading strategy.</p>  
                <div className='heading_option'>
                    <button onClick={() => history.push('/masterclass')}>Join Masterclass</button>
                    <button style = {{backgroundColor:'transparent', color:'#20AFFF'}}onClick={() => history.push('/freewebinar')}>Learn More &#62;</button>
                </div>
        </section>

        <section className='masterclass_section'>
            <div className='title_container'>
                <h1>Harmonics <span>Master Class</span></h1>
                <h2>The success rate of a trading strategy depends on proper backtesting and implementation. The harmonic pattern success rate is high, as it has been followed by many traders</h2>
            </div>

            <div className='description_container'>
                <section className='desc_masterclass'>      
                    <img src={desc_ic} alt="icon"/>
                    <h1>Learn Trading Strategies</h1>
                    <p>Acquire the exact trading strategies, and system that made traders knowledgeable in the market and generate more profit.</p>
                </section>

                <section className='desc_masterclass'>
                    <img src={desc_ic3} alt="icon"/>
                    <h1>Lifetime Access</h1>
                    <p>This is a LIFETIME ACCESS for you to have a complete guide along with your development and transformation as an independent harmonics trader.</p>
                </section>

                <section className='desc_masterclass'>
                    <img src={desc_ic2} alt="icon"/>
                    <h1>Community</h1>
                    <p>A community of more than 600 students is awaiting for you with good connection that can help you grow your network and connect to other traders</p>
                </section>
            </div>
                <button onClick={() => history.push('/masterclass/payment')}>Join Masterclass</button>
        </section>

        <section className='banner_section'>
                <Carousel fade="true" data-interval ="1000" style = {{width: "100%",position:'sticky', marginLeft:'.5em'}} >
                    <Carousel.Item id='testimonial'>
                        <img alt="first" className='="d-block w-100' src={pic1} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic2} alt="banner"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic3} alt="banner"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic4} alt="banner"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic5} alt="banner"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic6} alt="banner"/>
                    </Carousel.Item>
                </Carousel>
        </section>

        <section className='freewebinar_section'>
            <div className='free_title_container'>
                <h1>Free Webinar</h1>
                <h2>Learn how you can start trading cryptocurrency in your own, with proven and tested advance strategy</h2>
            </div>
            <div className='free_section_container'>

                <video style ={{opacity:'1'}} autoPlay loop muted src={vidfree} type="video/mp4"></video>
                <button onClick={() => history.push('/freewebinar')}>Get Access to Free Webinar</button>
            </div>
                    
        </section>

                <section className='testimonial_section'>
                <Carousel>
                    <Carousel.Item interval={1000}>
                        <img
                        className="d-block w-100"
                        src={t1}
                        alt="First slide"
                        />
                    </Carousel.Item>
                    <Carousel.Item interval={1000}>
                        <img
                        className="d-block w-100"
                        src={t2}
                        alt="second slide"
                        />
                    </Carousel.Item>
                </Carousel>
                </section>

                <Footer/>


              

             

               
                </div>
            </div>
            
            </>
        )
    }

export default HomeAuth
