import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Profile from '../../../components/Profile/Profile'
import { AuthContext } from '../../../context/AuthContext'
import '../studentlayout.css'


const Home = () => {
  const {user} = useContext(AuthContext)
  const [ncourses, setNCourses] = useState([])
  const [acourses, setACourses] = useState([])
  const [courses, setCourses] = useState([])
  const [edit, setEditProfile] = useState(false)

  let history = useHistory()

  useEffect(()=> {

   getCourse()
}, [])

const getCourse = () => {
    axios.get(`/api/courses/lesson-data-novice`).then(res => {
        if (res.data.success) {
            setNCourses(res.data.courses)
    }})

    axios.get(`/api/courses/lesson-data`).then(res => {
        if (res.data.success) {
            setCourses(res.data.courses)
    }})

    axios.get(`/api/courses/lesson-data-additional`).then(res => {
            if (res.data.success) {
               setACourses(res.data.courses)
            }
        })
    
}

const showProfile = () => setEditProfile(!edit);


  return (
    <div className='container-box'>
        <div className='greetings'>
            <h1><span>Hi! </span> Welcome { user.name}</h1>
          
            <button onClick={showProfile}>{edit === true ? 'Cancel' : 'Edit Profile'}</button>
            
            <button className='show_assesment' onClick={() => history.push('/assesment')}>Take Assessment</button>
        
            {edit === true ? <Profile/>:
            ''
            }
        </div>

    

    <div className='welcome_lesson'>
        {ncourses.slice(0,3).map((crs, index) => {
            return(
                <div  className= "novice_container" key={index}  >
                   <h1>Beginner Lesson {index + 1} <span onClick={() => history.push(`/watch/novice/${crs._id}/${index}`)}>View All</span></h1>
                  
                    <iframe src = {crs.resource_url} allowFullScreen="true" sandbox='allow-same-origin allow-scripts'></iframe>
                    
                    <h2>{crs.title} </h2>
                    <hr/>
                    {/* <h1>{crs.title}</h1>
                    <iframe src={crs.resource_url}allowFullScreen="true" sandbox="allow-same-origin allow-scripts" ></iframe> */}
                </div>
            )
        })}
        <hr/>
        {courses.slice(0,2).map((crs, index) => {
            return(
                <div  className= "novice_container" key={index} >
                   <h1>Master Lesson no. {index + 1} <span onClick={() => history.push('/watch/61c60c7eae1254d48214bdb9/0')}>View All</span></h1>
                   
                    <iframe src = {crs.resource_url} allowFullScreen="true" sandbox='allow-same-origin allow-scripts'></iframe>
    
                    <h2>{crs.title} </h2>
                    <hr/>
                    {/* <h1>{crs.title}</h1>
                    <iframe src={crs.resource_url}allowFullScreen="true" sandbox="allow-same-origin allow-scripts" ></iframe> */}
                </div>
            )
        })}
    </div>

    <div  className='additional_container'>
           
                    
    <table>
        <thead>
            <tr className="thead">
                <th scope="col"><h1>Bonus Lesson Links</h1></th>
              
            </tr>
        </thead>
        <tbody>

        {acourses.map((cours, index) => {
            return (
                <tr key={index}>

                    <td>  <a href={cours.resource_url} target='_blank' rel='noopener noreferrer'>{cours.title}</a></td>
                                   
                </tr>
            )
        })}

        </tbody>
        </table>
    </div>

    </div>
   
  )
}

export default Home