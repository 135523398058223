import React, { Component } from 'react'
import { MdOutlineDelete, MdOutlineLock } from "react-icons/md";
import Sidebar from '../../components/Sidebar/Sidebar';
import axios from 'axios';
import Register from '../../components/Register/Register';
import { useHistory } from 'react-router-dom';

export class ViewStudent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users:[], 
        }
    }
    componentDidMount() {
        this.getAccounts();
    }

    getAccounts() {
        axios.get("/api/courses/all-user").then(res => {
            if (res.data.success) {
                this.setState({
                    users: res.data.users,
                })
            }
        })
    }

    onActivate = (id) => {
        axios.put(`/api/courses/paid/${id}`, {
            status: 'paid'
        }).then((res) => {
            if (res.data.success) {
                this.getAccounts();
            }
        })
    }



    render() {
        return (
            <div className = "profilelayout">
             <Sidebar/>

          <div className = "additional_table">
             
                <h2>Total Students: {this.state.users.length}<span onClick={() => this.props.history.push(`/new/student`)}>Add New</span></h2>
                <table>
                        <thead>
                            <tr className="thead">
                                <th scope="col">Name</th>
                                <th scope="col">Account Created</th>
                                <th scope="col">Payment</th>
                                <th scope='col'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>

                        {this.state.users.map((user, index) => {
                                    this.state.datelogin = new Date(user.created).toLocaleDateString()  
                                
                                return (

                                    <tr key = {index}>
  
                                        <td>{user.name}</td>
                                        <td>
                                            {this.state.datelogin}
                                        </td>

                                        {/*<td>{user.password = Math.floor(Math.random()*1000*1000*1000)}</td>*/}
                                       

                                        <td className={user.status === 'pending' ? 'active' : "inactive"}>
                                            {user.payment}
                                        </td>

                                        <td>
                                            {user.payment ==='pending'? <a style={{marginRight:'1em'}} onClick={() => this.onActivate(user._id)}>
                                                Mark as Paid
                                            </a>: ''}
                                           
                                            
                                        </td>


                                    </tr>
                                )
                            })}


                        </tbody>
                        
                </table>
                  
                   
          </div>
          
      </div>
        )
    }
}


export const AddStudent =() => {
    let history = useHistory()

    return(
        <div className = "profilelayout">
            <Sidebar/>
            <div className = "additional_table">
             
             <h2>Add New Student<span onClick={() => history.goBack()}>Go Back</span></h2> 
                <div className='table'>
                <Register/>
                </div>
             
            
           
            </div>
           
        </div>
    );
}



export default {ViewStudent,AddStudent}
