import React, { useEffect } from 'react'
import axios from 'axios'
import { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useHistory } from 'react-router-dom';

function Logout() {
    let history = useHistory();
    const {dispatch} = useContext(AuthContext)
    useEffect(() =>{
        handleClick()
    },[])
    const handleClick = async (e) =>{
       
        try{
            await axios.get("/api/auth/signout")
            localStorage.removeItem("_appSigning")
            dispatch({type: "SIGNOUT"})
            history.push('/')
            
        }catch(err){
            console.log(err)
        }
    }


    return (
        <div>
            
        </div>
    )
}

export default Logout
