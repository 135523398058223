import React, { Component } from 'react'
import { MdOutlineDelete, MdOutlineLock } from "react-icons/md";
import Sidebar from '../../components/Sidebar/Sidebar';
import axios from 'axios';
import Register from '../../components/Register/Register';
import { useHistory } from 'react-router-dom';

export class ViewNFTList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users:[], 
        }
    }
    componentDidMount() {
        this.getAccounts();
    }

    getAccounts() {
        axios.get("/api/courses/all-user/nft").then(res => {
            if (res.data.success) {
                this.setState({
                    users: res.data.users,
                })
            }
        })
    }




    render() {
        return (
            <div className = "profilelayout">
             <Sidebar/>

          <div className = "additional_table">
             
                <table>
                        <thead>
                            <tr className="thead">
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Contact</th>
         
                            </tr>
                        </thead>
                        <tbody>

                        {this.state.users.map((user, index) => {
                                   
                                
                                return (

                                    <tr key = {index}>
  
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.contact}</td>
                                       


                                    </tr>
                                )
                            })}


                        </tbody>
                        
                </table>
                  
                   
          </div>
          
      </div>
        )
    }
}





export default ViewNFTList
