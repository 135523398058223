import React, { useState } from 'react'
import Appbar3 from '../Appbar/Appbar3'
import SidebarAuth from '../Sidebar/SidebarAuth';
import t1 from '../../images/testimonial.jpg'
import "bootstrap/dist/css/bootstrap.min.css"
import t2 from '../../images/testimoniall.jpg'
import Carousel from 'react-bootstrap/Carousel'
import {Footer} from '../Footer/Footer';
import vidfree from '../../images/vid.mp4'
import img from '../../images/wb.jpg';
import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty, isEmail} from "../helper/validate";
import Input from '../Input/Input';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const initialState = {
    name: "",
    email: "",
    contact:"",
  };

function FreeMasterAuth() {

    const [sidebar, setSidebar] = useState(false)
   
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }

    const [data, setData] = useState(initialState);
    const { email, name, contact } = data;

    
    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
        );
        setData({ ...data, name: "", email: "", contact: "" });
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      };

      const register = async (e) => {
        e.preventDefault()
       
        // check fields
        if (isEmpty(name))
          return toast("Please fill in all fields.", {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
        // check email
        if (!isEmail(email))
          return toast("Please enter a valid email address.", {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
       
        try {
          const res = await axios.post("/api/auth/freeweb", {
            name,
            email,
            contact,
          });
          toast(res.data.msg, {
            className: "toast-success",
            bodyClassName: "toast-success",
          });
        
          handleReset()
   
          
        } catch (err) {
          toast(err.response.data.msg, {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
        }
        
     
      };
    return (
        <>
        <ToastContainer/>
        <div className='main-container-free'>
        <MessengerCustomerChat
        pageId="104959258728460"
        appId="2837583293208170"
        minimized="true"
    />
       <Appbar3 handleSidebar={handleSidebar}/>

        <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
        <SidebarAuth />
        </div>
        
            <div className='content_container'>
              
              <section className='title_section'>
              <h1>How to start your trading career in the Philippines? </h1>

              </section>

              <section className='video_section'>
              <div className ="video_description">
              <h2>Learn how you can start trading cryptocurrency in your own, with proven and tested advance strategy</h2>
                  <ol>
                      <li>Discover the trading skills you need to make profit in the market that you could acquire and use for long term.</li>
                      <li>Trading system that will serve as the backbone of your strategy.</li>
                      <li>The training that you have to take in order to develop your new high-income skill</li>
                      <li>The continuous support and assistance that you will get along with your learning process.</li>
                  </ol>
                  <p style={{color:'#00A8FF'}}>Please fill up the field below and an access will be sent to you!</p>
           
              
                  <form onSubmit={register} className='freewebinar'>
             
                <Input
                    type="text"
                    text="Full Name"
                    name="name"
                    handleChange={handleChange}
                />
                <Input
                    type="email"
                    text="Email"
                    name="email"
                    handleChange={handleChange}
                />
                <Input
                    type="text"
                    text="Contact Information"
                    name="contact"
                    handleChange={handleChange}
                />
                {/* <button>Proceed to Payment</button> */}
                
                <button type ="submit"  >Access Free Webinar!</button>  

                 
             
                </form>
              </div>
              <div className="video_container">
              <video src = {vidfree} autoPlay loop ></video>
              </div>
              </section>
              </div>        
          <Carousel fade="true" data-interval ="1000" style={{position:'sticky'}}>
              <Carousel.Item id='testimonial'>
                  <img alt="first" className='="d-block w-100' src={t1} style = {{height: "100%"}} />
              </Carousel.Item>
              <Carousel.Item>
                  <img className='="d-block w-100' src={t2} alt="img"/>
              </Carousel.Item>
          </Carousel>
          <img src = {img} alt="poster"/>

          <Footer/>
      </div>
      </>
    )
    
}

export default FreeMasterAuth;

