import Input from "../Input/Input";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './forgot.css'
import logo from '../../images/logo.png'
import React, { useState } from 'react'
import Appbar from "../Appbar/Appbar";
import {Footer} from "../Footer/Footer";
import SidebarAuth from "../Sidebar/SidebarAuth";


const initialState = {
    email: ""
  };

const Forgot = () =>{
    const [data, setData] = useState(initialState);
    const [sidebar, setSidebar] = useState(false)
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }
    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      };
    const { email } = data;
    const forgot = async (e) => {
        e.preventDefault();
        // check fields
        try {
          const res = await axios.post("/api/auth/forgot_pass", { email })
          
          return toast(res.data.msg,{
            className: "toast-success",
            bodyClassName: "toast-success"
        })
         
        
        } catch (err) {
          toast(err.response.data.msg, {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
        }
      };
    return(
        <>
        <ToastContainer/>
<div className="forgot_container">
      <Appbar handleSidebar={handleSidebar}/>

      <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
      <SidebarAuth />
      </div>

        <img src = {logo} style ={{width:'250px'}} />
        Please Enter your Email
        <form onSubmit={forgot} style={{marginTop:'2em'}} >
    
        <Input type = "text" text="Email" name="email"  handleChange={handleChange}/>
       
            <button type="submit">send</button>
   
        </form>

        {/* <Footer/> */}
        </div>
        </>
  )
}

export default Forgot