import Input from "../Input/Input";
import {useHistory, useParams} from 'react-router-dom'
import { MdVisibility } from "react-icons/md"
import { MdVisibilityOff } from "react-icons/md"
import { useState } from "react"
import './reset.css'
import { isEmpty, isLength, isMatch } from "../helper/validate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import SidebarAuth from "../Sidebar/SidebarAuth";
import Appbar from "../Appbar/Appbar2";



const initialState = {
    password:'',
    cf_password:''
}
const Reset = () => {

    const [visible, setVisible] = useState(false)
    const [data, setData ] = useState(initialState)
    const {password, cf_password} = data
    const [sidebar, setSidebar] = useState(false)
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }

    const {token} = useParams()
    let history = useHistory();

    const handleClick = () => {
        setVisible(!visible);
    };

    const handleSubmit = async(e) =>{
        e.preventDefault()

        if (isEmpty(password) || isEmpty(cf_password))
        return toast("Please fill in all fields.", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        });

        if (isLength(password))
        return toast("Password must be at least 6 characters", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        })

     

        if (!isMatch(password, cf_password))
        return toast("Password did not match", {
          className: "toast-failed",
          bodyClassName: "toast-failed",
        })

        try{
            await axios.post("/api/auth/reset_pass",{password},
            {
                headers: {Authorization:token}
            })
            return toast("Password was succesfully updated",{
                className: "toast-success",
                bodyClassName: "toast-success"
            }), history.push('/')

     

        }catch(err){
            toast(err.response.data.msg,{
                className: "toast-failed",
                bodyClassName: "toast-failed",
            })
        }

       
            
    }

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      };
    return (
        <>
        <ToastContainer/>
        <Appbar handleSidebar={handleSidebar}/>

<div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
<SidebarAuth />
</div>
            <div className="forgot_container">
            <form onSubmit={handleSubmit} >
        <Input type={visible ? "text" : "password"}
        name="password"
        handleChange={handleChange}
            icon={visible ? <MdVisibility /> : <MdVisibilityOff />}
             text="Password" handleClick={handleClick} />
        <Input type={visible ? "text" : "password"}
        handleChange={handleChange}
        name="cf_password"
            icon={visible ? <MdVisibility /> : <MdVisibilityOff />} text="Confirm Password" handleClick={handleClick} />
       
            <button type = "submit">reset password</button>
     
          </form>
            </div>
    
          </>
          )
         
}
export default Reset