import React, { useState } from 'react'
import Appbar4 from '../Appbar/Appbar4'
import SidebarAuth from '../Sidebar/SidebarAuth';

import "bootstrap/dist/css/bootstrap.min.css"
import './nft.css'

import bg from '../../images/1980.jpg'
import {FooterSocialMedia,Footer} from '../Footer/Footer';

import axios from 'axios'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isEmpty, isEmail} from "../helper/validate";
import Input from '../Input/Input';
import { useHistory } from 'react-router-dom';


const initialState = {
    name: "",
    email: "",
    contact:"",
  };

function Nft() {
    let history = useHistory();
  
    const [sidebar, setSidebar] = useState(false)
   
    const handleSidebar = () =>{
        setSidebar(!sidebar)
    }

    const [data, setData] = useState(initialState);
    const { email, name, contact } = data;

    
    const handleReset = () => {
        Array.from(document.querySelectorAll("input")).forEach(
        (input) => (input.value = "")
        );
        setData({ ...data, name: "", email: "", contact: "" });
    };

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
      };

      const register = async (e) => {
        e.preventDefault()
       
        // check fields
        if (isEmpty(name))
          return toast("Please fill in all fields.", {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
        // check email
        if (!isEmail(email))
          return toast("Please enter a valid email address.", {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
       
        try {
          const res = await axios.post("/api/auth/nft", {
            name,
            email,
            contact,
          });
          toast(res.data.msg, {
            className: "toast-success",
            bodyClassName: "toast-success",
          });
        
          handleReset()
   
          
        } catch (err) {
          toast(err.response.data.msg, {
            className: "toast-failed",
            bodyClassName: "toast-failed",
          });
        }
        
     
      };
    return (
        <>
        <ToastContainer/>
        <div className='nft-container' >
       <Appbar4 handleSidebar={handleSidebar}/>

        <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
        <SidebarAuth />
        </div>

        <div className='nft-content'>
          <img src={bg}/>
            <form className ='nft-form'>

            <h1 className='h1'>Get a chance to be whitelisted! Register now!</h1>
       
          

            <a href = "https://forms.gle/4UToiGNg7N3vCpJq6" target='_blank'>Premium Registration</a>  
            <a href='https://forms.gle/2jLWraiLek4fYFMZ6' target='_blank'>Non-Premium Registration</a>  

            

            </form>

          

           
            
            
        </div>
     
        
        
          

         
      </div>
      </>
    )
    
}

export default Nft;

