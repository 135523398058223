import { useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import "./profilelayout.css"
import logo from '../../images/logo.png'
import React, { Component } from 'react'
import axios from 'axios'
import { useHistory } from "react-router-dom";
import {MdOutlineDelete} from 'react-icons/md'
import Profile from "../../components/Profile/Profile";

export class ProfileLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: [],
            sidebar:false,
            courses_additional: [],
            courses_novice:[],
            users:[], 
            rev:[]
        }
    }
    componentDidMount() {
        this.getCourse();
        this.getAdditional();
        this.getUser()
        this.getRevenue();
        this.getNovice();
    }

    getCourse() {
        axios.get(`/api/courses/lesson-data`).then(res => {
            if (res.data.success) {
                this.setState({
                    courses: res.data.courses,
                })
            }
        })
    }
    getUser() {
        axios.get(`/api/courses/all-user`).then(res => {
            if (res.data.success) {
                this.setState({
                    users: res.data.users,
                })
                
            }
           
        })
    }

    getRevenue() {
        axios.get(`/api/courses/all-user/paid`).then(res => {
            if (res.data.success) {
                this.setState({
                    rev: res.data.users,
                })
                
            }
           
        })
    }
    getAdditional() {
        axios.get(`/api/courses/lesson-data-additional`).then(res => {
            if (res.data.success) {
                this.setState({
                    courses_additional: res.data.courses,
                })
                
               
            }
        })
    }
    getNovice() {
        axios.get(`/api/courses/lesson-data-novice`).then(res => {
            if (res.data.success) {
                this.setState({
                    courses_novice: res.data.courses,
                })    
            }
        })
    }
    handleSidebar=() =>{
        this.setState({
            sidebar: !this.state.sidebar
        });
    }

    onDeleteModule = (id) => {
        axios.delete(`/api/courses/lesson/module/delete/${id}`).then((res) => {
            if(res.data.success){
                alert("Deleted Succesfully")
                this.setState({lesson_title:''})
                this.getCourse();
            }
        })
    }
    render() {
    return(
      
           <div className = "profilelayout">
             <Sidebar/>      
          <div className = "profilelayout_content">

              
              <h1>Dashboard</h1>
              <img src = {logo} />

                <div className="profilelayout_data">        
                <h2>Total Earnings:<span>PHP {this.state.rev.length * 2500}</span></h2>
                <h2>Total Students:<span>{this.state.users.length}</span></h2>
                <h2>Total Master Class:<span>{this.state.courses.length}</span></h2>
                <h2>Total Additional Lessons:<span>{this.state.courses_additional.length}</span></h2>
                
                </div>
                <div className='show_profile'>
                     <Profile/>
                </div>

               
                <div className="profilelayout_content-lessons">        
                    <h2>Novice Class</h2>
                 
                    
                   {this.state.courses_novice.slice(0,5).map((cours, index) => {
                            return (
                                <div className="lesson_div-show"  key = {index}>
                                    
                                   <a onClick={() => this.props.history.push(`/watch/novice/${cours._id}/${index}`)}>{cours.title}</a>                                    
                                        
                                        
                                        
                                        <p>{cours.content}</p>
                            
                                        
                                </div>
                            )
                        })}
                </div>
                <div className="profilelayout_content-lessons">        
                    <h2>Master Class</h2>
                    <h3 onClick={() => this.props.history.push('/watch/61c60c7eae1254d48214bdb9/0')}>View All</h3>
                    
                   {this.state.courses.slice(0,5).map((cours, index) => {
                            return (
                                <div className="lesson_div-show"  key = {index}>
                                    
                                    <p>{cours.title} <span onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Module?"
                                            )
                                            if (confirmBox === true) {
                                                this.onDeleteModule(cours._id)
                                            }
                                        }}><MdOutlineDelete /></span>
                                       
                                        
                                        </p>
                                        
                                        <p>{cours.content}</p>
                            
                                        
                                </div>
                            )
                        })}
                </div>
                    
                <div className="profilelayout_content-users">        
                    <h2>Students</h2>
                    <h3 onClick={() => this.props.history.push('/students')}>View All</h3>
                    
                   {this.state.users.slice(0,5).map((user, index) => {
                            return (
                                <div className="lesson_div-show"  key = {index}>
                                    
                                    <p>{user.name} <span onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Module?"
                                            )
                                            if (confirmBox === true) {
                                                this.onDeleteModule(user._id)
                                            }
                                        }}><MdOutlineDelete /></span>
                                       
                                        
                                        </p>                        
                                </div>
                            )
                        })}
                </div>
    
                <div className="profilelayout_additional-lessons">
                   <h2>Additional Class</h2>
                    <h3 onClick={() => this.props.history.push(`/new`)}>View All</h3>
                    {this.state.courses_additional.map((cours, index) => {
                            return (
                                <div className="lesson_div-show"  key = {index}>
                                    {cours.content === 'additional'  ? 
                              
                              
                                    <a href = {cours.resource_url}>{cours.title} <span onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Module?"
                                            )
                                            if (confirmBox === true) {
                                                this.onDeleteModule(cours._id)
                                            }
                                        }}><MdOutlineDelete /></span>
                                                                               
                                        </a>

                                        : <p>Hi</p>
                                    
                         }
                                </div>
                            )
                    })}
                </div>

             
          </div>
          
      </div>
     
    );

    }
}

export const ProfileLayoutNew =() => {
    const [sidebar, setSidebar] = useState(false);
    const [newLesson, setnewLesson] = useState({
        title: "",
        content: "",
        resource_url: "",
    })
    const history = useHistory()

    const handleSidebar=() =>{
        setSidebar(!sidebar);
    }
    const handleChange = (e) => {
        setnewLesson({ ...newLesson, [e.target.name]: e.target.value })
    }

    const handleFile =(e) =>{
        setnewLesson({...newLesson, resource_url: e.target.files[0] })
        console.log(newLesson.resource_url)
    }
        const handleSubmit = (e) => {
            e.preventDefault();
    
            const formData = new FormData();
            formData.append('title', newLesson.title)
            formData.append('content', newLesson.content)
            formData.append('resource_url', newLesson.resource_url)   
            console.log(newLesson)
    
            axios.put(`/api/courses/add-lesson/`, formData).then(res => {
                console.log(res);
                history.goBack()
            })
                .catch(err => {
                    console.log(err)
            })
    
        }

    return(
        <div className = "profilelayout">
            <Sidebar/>
            <div className = "additional_table">
          
            <h2>New Additional Lesson <span onClick={() => history.goBack()}>Go Back</span></h2>

            <form onSubmit={handleSubmit} autoComplete ="off"encType='multipart/form-data' className = "form-lesson">
                    <div className="form-group">
                    <label>Resources:</label>
                        <input
                            type="file"
                            accept= ".mp4, .pdf, .mkv"
                            className="thumbnail-input"
                            name="resource_url"
                            onChange={handleFile}
                            
                           
                        />
                    </div>

                    <div className="form-group">
                    <label>Title:</label>
                        <input
                            type="text"
                            name="title"
                            className="title"
                            value={newLesson.title}
                            onChange={handleChange}
                            
                        />
                    </div>

                    <div className="form-group">
                        <label>Content:</label>
                        <input
                            type="text"
                            name="content"
                            className="content"
                            value={newLesson.content}
                            onChange={handleChange}
                        />
                    </div>
                    
                    <div className="form-group">
                        <button type="submit" className="submit">Submit</button>
                     </div>

                </form>
            
           
            </div>
            
        </div>
    );
}

export default {ProfileLayout,ProfileLayoutNew}


