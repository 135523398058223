import './resetlayout.css'
import Reset from '../../components/Reset/Reset'
import logo from '../../images/logo.png'

const Resetlayout = ({ history }) =>{

    const handleClick = () =>{
        history.push("/")
    }
    return(<div style = {{display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
        <img src = {logo} style ={{width:'300px'}} />
    <Reset/>
  
    </div>)
}

export default Resetlayout