import axios from 'axios';
import React, { Component } from 'react'
import { MdOutlineDelete, MdOutlineLock } from "react-icons/md";
import Sidebar from '../../components/Sidebar/Sidebar';

export class ViewAdditionalClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses_additional: [], 
        }
    }
    componentDidMount() {
        this.getAdditional();
    }

    getAdditional() {
        axios.get(`/api/courses/lesson-data-additional`).then(res => {
            if (res.data.success) {
                this.setState({
                    courses_additional: res.data.courses,
                })
                
               
            }
        })
    }
    render() {
        return (
            <div className = "profilelayout">
             <Sidebar/>

          <div className = "additional_table">
             
                <h2>Total Additional Class: {this.state.courses_additional.length}<span onClick={() => this.props.history.push(`/new`)}>Add New</span></h2>
                <table>
                        <thead>
                            <tr className="thead">
                                <th scope="col">Title</th>
                                <th scope="col">Source</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.courses_additional.map((course) => {
                                
                                return (

                                    <tr>
                                    <td>{course.title}</td>
                                    <td><a>{course.resource_url}</a></td>
                                    <td onClick={() => {
                                            const confirmBox = window.confirm(
                                                "Do you really want to delete this Module?"
                                            )
                                            if (confirmBox === true) {
                                                this.onDeleteModule(course._id)
                                            }
                                        }}><MdOutlineDelete /><span><MdOutlineLock/></span></td>
                                    </tr>
                                   
                                )
                            })}


                        </tbody>
                        
                    </table>
                  
                   
          </div>
          
      </div>
        )
    }
}

export default ViewAdditionalClass
