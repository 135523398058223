import Input from "../Input/Input";
import { useState } from "react";
import { isEmpty, isEmail, isLength, isMatch } from "../helper/validate";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './register.css'

const initialState = {
  name: "",
  email: "",
  password: "",
  cf_password: "",
  contact:"",
  payment:""
};

const Register = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState(initialState);
  const { name, email, contact, payment } = data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  const register = async (e) => {
 e.preventDefault()
    // check fields
    if (isEmpty(name))
      return toast("Please fill in all fields.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    // check email
    if (!isEmail(email))
      return toast("Please enter a valid email address.", {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
  
    try {
      
      const res = await axios.post("/api/auth/register", {
        name,
        email,
        contact,
        payment:'paid'
      });
      toast(res.data.msg, {
        className: "toast-success",
        bodyClassName: "toast-success",
      });
    } catch (err) {
      toast(err.response.data.msg, {
        className: "toast-failed",
        bodyClassName: "toast-failed",
      });
    }

  };

  const handleReset = () => {
    Array.from(document.querySelectorAll("input")).forEach(
      (input) => (input.value = "")
    );
    setData({ ...data, name: "", email: "" });
  };

  return (
    <>
      <ToastContainer />
      <div className="register">
      <form onSubmit={register} >
        <Input
          type="text"
          text="Name"
          name="name"
          handleChange={handleChange}
        />
        <Input
          type="text"
          text="Email"
          name="email"
          handleChange={handleChange}
        />

            <Input
                    type="text"
                    text="Contact Information"
                    name="contact"
                    handleChange={handleChange}
                />
      
        
          <button type="submit">register</button>
   
      </form>
      </div>
    </>
  );
};

export default Register;