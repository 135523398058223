import axios from 'axios';
import React, { Component } from 'react'
import SidebarStudent from '../../components/SidebarStudent/SidebarStudent';

export class ExtraLessons extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses_additional: [], 
            selectedIndex: parseInt(this.props.match.params.lesson)
        }

        this._TogglePrev = this._TogglePrev.bind(this);
        this._ToggleNext = this._ToggleNext.bind(this);
    }
    componentDidMount() {
        this.getAdditional();
    }

    getAdditional() {
        axios.get(`/api/courses/lesson-data-additional`).then(res => {
            if (res.data.success) {
                this.setState({
                    courses_additional: res.data.courses,
                })
                
               
            }
        })
    }

    _ToggleNext(id, index) {
        if(this.state.selectedIndex == this.state.courses_additional.length - 1)
  
            return;

        this.setState(prevState => ({
            selectedIndex: prevState.selectedIndex + 1
        }))
        index = index+ 1
        this.props.history.push(`/additional-lessons/watch/${id}/${index}`)
    }

    _TogglePrev(id, index) {
        if(this.state.selectedIndex == 0)
         return;

        this.setState(prevState => ({
            selectedIndex: prevState.selectedIndex - 1
        }))
        index = index-1
        this.props.history.push(`/additional-lessons/watch/${id}/${index}`)
    }
    render() {
        return (
            <div className = "profilelayout">
             <SidebarStudent/>

          <div className = "additional_table">
             
                <h2>Additional Classes</h2>
                {this.state.courses_additional.length === 0? <p>No Additional Lessons for now!</p> :
                    
                    <table style={{width: '80%'}}>
                    <thead>
               
                        <tr className="thead">
                            <th scope="col">Title</th>
                     
                 
                        </tr>
                    </thead>
                    <tbody>

                    {this.state.courses_additional.map((cours, index) => {
                        return (
                            <tr key={index}>
    
    <td>  <a href={cours.resource_url} target='_blank' rel='noopener noreferrer'>{cours.title}</a></td>
                         
                                        
                                    
                                                 
                            </tr>
                        )
                    })}

                    </tbody>
                    
                </table>}
                  
                   
          </div>
          
      </div>
        )
    }
}

export default ExtraLessons
