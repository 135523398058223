import './sidebar.css'
import { Link, useHistory } from 'react-router-dom'
import Modal from 'react-modal';
import Login from '../Login/login';
import React, { useState } from 'react'

const customStyles = {
    content : {
  
      top                   : '40%',
      left                  : '50%',
      right                 : 'auto',
      position                :'fixed',
      bottom                : 'auto',
      padding               : '1em',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
        
      
    }
};
const SidebarAuth = () => {
    const [modalIsOpen,setModalIsOpen] = useState(false);
    const [login, setLogin] = useState(false)
    const history = useHistory()
    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
        setLogin(true)
 
    }

    return (
        <div>
            <div className = "sidebar">
             
                <div className={login ? 'home_sidebar' : 'sidebar_menu'}>
                    <ul>
                        <li><p><Link to = '/'>Home</Link></p></li>
                        <li><p><Link to = "/freewebinar"> Free Webinar</Link></p> </li>
                 
                        <li><p><Link to = '/masterclass'> Masterclass</Link></p></li>
                     
                        <li onClick={setModalIsOpenToTrue}><p><a>Login</a></p></li>

                    </ul>
                </div>
            </div>
            <Modal isOpen={modalIsOpen} ariaHideApp={false} style={customStyles} onRequestClose={()=> setModalIsOpen(false)} >
                                <Login/>
                            
                            </Modal>                            
        </div>
    )
}

export default SidebarAuth
