import React, {useState} from 'react'
import Appbar2 from '../Appbar/Appbar2'
import SidebarAuth from '../Sidebar/SidebarAuth'
import { useHistory } from 'react-router-dom';
import { PayPalButton } from "react-paypal-button-v2";
import './payment.css'
import Input from '../Input/Input';
import { isEmpty, isEmail, isLength, isMatch } from "../helper/validate";
import promo from '../../images/banner-1.jpg'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios'
import Carousel from 'react-bootstrap/Carousel'
import pic1 from '../../images/pic1.png'
import pic2 from '../../images/pic2.png'
import pic3 from '../../images/pic3.png'
import pic4 from '../../images/pic4.png'
import pic5 from '../../images/pic5.png'
import pic6 from '../../images/pic6.png'
import {Footer} from '../Footer/Footer';


const initialState = {
    name: "",
    email: "",
    contact:"",
  };
function Payment() {
    let history = useHistory();
        const [sidebar, setSidebar] = useState(false)
        const [pay, showPay] = useState(false)
        const [data, setData] = useState(initialState);
        const [disabled, setDisabled] = useState(false);
        const { email, name, contact } = data;
        const handleSidebar = () =>{
            setSidebar(!sidebar)
        }
        const handlePay = () =>{
          showPay(false)
          handleCancel()
      }

      function handleGameClick() {
        setDisabled(!disabled);
      }
      
       

        
        const handleReset = () => {
            Array.from(document.querySelectorAll("input")).forEach(
            (input) => (input.value = "")
            );
            setData({ ...data, name: "", email: "", contact: "" });
        };

        const handleDisable = () => {
          Array.from(document.querySelectorAll("input")).forEach(
          (input) => input.disabled = true
          );
      };

      
      const handleCancel = () => {
        Array.from(document.querySelectorAll("input")).forEach(
        (input) => input.disabled = false
        );
    };

        const handleChange = (e) => {
            setData({ ...data, [e.target.name]: e.target.value });
          };
          const details = async (e) =>{
           
            e.preventDefault()
           
            // check fields
            if (isEmpty(name))
              return toast("Please fill in all fields.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
              });
            // check email
            if (!isEmail(email))
              return toast("Please enter a valid email address.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
              });
              showPay(!pay)
              handleDisable()
          }
          const register = async (e) => {
            e.preventDefault()
           
            // check fields
            if (isEmpty(name))
              return toast("Please fill in all fields.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
              });
            // check email
            if (!isEmail(email))
              return toast("Please enter a valid email address.", {
                className: "toast-failed",
                bodyClassName: "toast-failed",
              });
           
            try {
              const res = await axios.post("/api/auth/register", {
                name,
                email,
                contact,
              });
              toast(res.data.msg, {
                className: "toast-success",
                bodyClassName: "toast-success",
              });
              handleReset()
              
            } catch (err) {
              toast(err.response.data.msg, {
                className: "toast-failed",
                bodyClassName: "toast-failed",
              });
            }
            
         
          };
          

    return (
        <>
        <ToastContainer/>
        <div className='payment-container-main'>
            <Appbar2 handleSidebar={handleSidebar}/>

            <div className={sidebar ? "home_sidebar open" : "home_sidebar"}>
            <SidebarAuth />
            </div>

            
            <div className='container-masterclass'>
            <Carousel fade="true" data-interval ="1000" style = {{width: "100%", position:'sticky', marginLeft:'.5em'}}>
                    <Carousel.Item id='testimonial'>
                        <img alt="first" className='="d-block w-100' src={pic1} />
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic2}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic3}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic4}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic5}/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className='="d-block w-100' src={pic6}/>
                    </Carousel.Item>
                </Carousel>
            <div className='payment-container'>

                <form onSubmit={register} className='input'>

                <h1>1. Payment and Personal Information</h1>
               
                <Input
                    type="text"
                    text="Full Name"
                    name="name"
                    handleChange={handleChange}
            
                />
                <Input
                    type="email"
                    text="Email"
                    name="email"
                    handleChange={handleChange}
         
                />
                <Input
                    type="text"
                    text="Contact Information"
                    name="contact"
              
                />
                <button disabled>Batch 4 Coming soon</button>
                      {/* {pay? '' : <button onClick={details}>Continue</button>}
                       <p>2. Settle your account</p>
                       <hr/> */}
                {/* <button>Proceed to Payment</button> */}
                
       
                   
                 <div className={pay ? "pay_open" : "pay"}>
                   
                 <button onClick={handlePay}>Cancel</button>

                 <p>2. Settle your account</p>
                       <hr/>
                   <p style={{ fontSize:'15px'}}>Thank you for signing up! Stop waiting! Waiting for things to change, waiting for "the right moment", waiting to take action and <strong>start creating life that you want. Now is the time to join our trading course</strong> and learn  how to achieve your goals. <strong>Secure your Slot now!</strong>
                   kindly send your payment thru:</p>
                   <p style={{ fontSize:'15px'}}>BPI<br/>Account Name: Anthony Louis Corpuz <br/> Account number: 8579-3168-03  </p>
                   <br/>
                  
                   <p style={{ fontSize:'15px'}}>UnionBank<br/>Account Name: Anthony Louis Corpuz <br/> Account number: 1094-2777-7641 </p>
                   <br/>
                   <p style={{ fontSize:'15px'}}>E-wallets<br/>Gcash/Paymaya/CoinsPH<br/>Account Name: Anthony Louis Corpuz <br/>Account Number: 09451341801</p>
                   <br/>
 
                   <p style={{ fontSize:'15px'}}>Kindly take a photo/screenshot of your proof of transaction and email it to: <br/>xcryptacesacademy@gmail.com or send it via facebook app: <strong>Xcrypt Aces</strong></p>
                   <p style={{ fontSize:'15px'}}>Here's the thing, you can use your problems as an excuse not to do something, or you can turn your problems to opportunities. ALways remember that everything good starts with a yes! and yes! my name is Anthony Louis Corpuz and see you on Xcrypt Aces soon. </p>
                   <button type="submit">REGISTER</button>
                 </div>

                 
             
                </form>

              
                 
               
           
           
             
            </div>
        </div>

        <Footer/>

       

            
            
        </div>
        </>
    )
}

export default Payment
