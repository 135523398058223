import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import AuthLayout from "./Layouts/AuthLayout/AuthLayout";
import {ProfileLayout, ProfileLayoutNew} from "./Layouts/ProfileLayout/ProfileLayout";
import ResetLayout from "./Layouts/ResetLayout/ResetLayout";
import AssesmentLayout from "./Layouts/AssesmentLayout/AssesmentLayout";
import { AuthContext } from "./context/AuthContext";
import { useContext, useEffect } from "react";
import axios from "axios";
import MasterAuth from "./components/MasterAuth/MasterAuth";
import WatchLayout from "./Layouts/WatchLayout/WatchLayout";
import Logout from "./Layouts/ProfileLayout/Logout";
import ViewAdditionalClass from "./Layouts/ProfileLayout/ViewAdditionalClass";
import {AddStudent, ViewStudent} from "./Layouts/ProfileLayout/ViewStudent";
import Activatelayout from "./Layouts/ActivateLayout/Activatelayout";
import StudentLayout from "./Layouts/StudentLayout/StudentLayout"
import ExtraLessons from "./Layouts/StudentLayout/ExtraLessons";
import Payment from "./components/Payment/Payment";
import FreeMasterAuth from "./components/MasterAuth/FreeMasterAuth";
import Faqs from "./components/Faqs/Faqs";
import PrivacyPolicy from "./components/Faqs/PrivacyPolicy";
import StartAssesment from "./Layouts/AssesmentLayout/StartAssesment";
import Nft from "./components/NFTAuth/Nft";
import NoviceLessons from "./Layouts/StudentLayout/NoviceLessons";
import ViewNFTList from "./Layouts/ProfileLayout/ViewNFTList";
import Forgot from "./components/Forgot/Forgot";
import TermsAndCondition from "./components/Faqs/TermsAndCondition";





function App() {
  const { dispatch, token, isLoggedIn, user } = useContext(AuthContext);

  // get ac token
  useEffect(() => {
    const _appSignging = localStorage.getItem("_appSignging");
    if (_appSignging) {
      const getToken = async () => {
        const res = await axios.post("/api/auth/access", null);
        dispatch({ type: "GET_TOKEN", payload: res.data.ac_token });
      };
      getToken();
    }
  }, [dispatch, isLoggedIn]);

  // get user data
  useEffect(() => {
    if (token) {
      const getUser = async () => {
        dispatch({ type: "SIGNGING" });
        const res = await axios.get("/api/auth/user", {
          headers: { Authorization: token },
        });
        dispatch({ type: "GET_USER", payload: res.data });
      };
      getUser();
    }
  }, [dispatch, token]);

  return (
    <Router>
     <Switch>
          
        
{user.role === 'student' &&
          (
      <Switch>
         <Route
          path="/logout"
          exact
          component={Logout}
        />
        <Route
          path="/"
          exact
          component={isLoggedIn ? StudentLayout : AuthLayout}
        />

         <Route
          path="/watch/:id/:lesson"
          exact 
          component={isLoggedIn? WatchLayout : AuthLayout}
        />

       
       <Route
          path="/additional-lessons"
          exact 
          component={isLoggedIn? ExtraLessons : AuthLayout}
        />
        <Route
          path="/watch/novice/:id/:lesson"
          exact 
          component={isLoggedIn? NoviceLessons : AuthLayout}
        />

<Route
          path="/assesment"
          exact 
          component={isLoggedIn? StartAssesment: AuthLayout}
        />

<Route
          path="/assesment/start"
          exact 
          component={isLoggedIn? AssesmentLayout: AuthLayout}
        />


      </Switch>
          )}
     
     {user.role === 'admin' &&
          (
      <Switch>
        <Route
          path="/"
          exact
          component={isLoggedIn ? ProfileLayout : AuthLayout}
        />

         <Route
          path="/new"
          exact
          component={isLoggedIn? ProfileLayoutNew: AuthLayout}
        />
         <Route
          path="/watch/:id/:lesson"
          exact 
          component={isLoggedIn? WatchLayout : AuthLayout}
        />
         <Route
          path="/additional"
          exact 
          component={isLoggedIn? ViewAdditionalClass : AuthLayout}
        />

        <Route
          path="/students"
          exact 
          component={isLoggedIn? ViewStudent : AuthLayout}
        />
        <Route
          path="/new/student"
          exact 
          component={isLoggedIn? AddStudent : AuthLayout}
        />
        
        <Route
          path="/nftwhitelist"
          exact 
          component={isLoggedIn? ViewNFTList : AuthLayout}
        />
        
        <Route
          path="/logout"
          exact
          component={Logout}
        />

      </Switch>
          )}

      <Route
          path="https://xcryptaces.com/api/auth/activate/:activation_token"
          exact
          component={Activatelayout}
        />
      

      <Route
          path="/masterclass"
          exact
          component={MasterAuth}
        />
        <Route
          path="/"
          exact
          component={isLoggedIn ? StudentLayout : AuthLayout}
        />


         <Route
          path="/masterclass/payment"
          exact
          component={Payment}
        />

<Route
          path="/FAQs"
          exact
          component={Faqs}
          />

          <Route
          path="/freewebinar"
          exact
          component={FreeMasterAuth}
          />
          
          <Route
          path="/nft/whitelist"
          exact
          component={Nft}
          />

        <Route
          path="/termsandconditions"
          exact
          component={TermsAndCondition}
          />

<Route
          path="/privacypolicy"
          exact
          component={PrivacyPolicy}
          />
 

<Route
          path="/auth/reset-password/:token"
          exact
          component={ResetLayout}
        />

        <Route
          path="/forgot_pass"
          exact
          component={Forgot}
        />

       

      </Switch>
    </Router>
  );
}

export default App;