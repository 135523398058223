
import './appbar.css'
import { Link } from 'react-router-dom';
import { BiMenuAltLeft } from 'react-icons/bi';
import Modal from 'react-modal';
import Login from '../Login/login';
import React, { useState } from 'react'
import logo from '../../images/logo.png'

const customStyles = {
    content : {
        top                   : '40%',
        left                  : '50%',
        right                 : 'auto',
        // position                :'fixed',
        bottom                : 'auto',
   
        transform             : 'translate(-50%, -50%)',
        backgroundColor   : '#f0f0f0'
    },
    logoContainer:{
        position:'absolute', 
        left:'1em', 
        display:'flex', 
        justifyContent:'center', 
        alignItems:'center',
        top:'0'
    },
    selected:{
        color: '#20AFFF',
        borderBottom:'4px solid #20AFFF',
        padding:'10px',
        paddingBottom:'13px'
   },
};
const Appbar3 = ({handleSidebar}) =>{
    const [modalIsOpen,setModalIsOpen] = useState(false);
    const [selected, isSelected] = useState("");

    const setModalIsOpenToTrue =()=>{
        setModalIsOpen(true)
    }
    
    return(
        <div className = "appbar">
            <div className = "appbar_wrapper">
                
                <div style={customStyles.logoContainer}>
                    <img src = {logo} alt ="logo"/>
                    <h1>Xcrypt Aces</h1>
                </div>
                
                
                <div className='appbar_menu'>
                    <ul>
                        <li > <Link to = '/'>Home</Link></li>
                        <li onClick={()=> isSelected("")}><Link to = '/freewebinar' style={ selected === ""? customStyles.selected: null}>Free Webinar</Link></li>
                        <li ><Link to = '/masterclass' >Masterclass</Link></li>
                        <li  className = 'login_lnk'onClick={setModalIsOpenToTrue}>Login</li>
                    </ul>
                    <Modal isOpen={modalIsOpen} ariaHideApp={false} style={customStyles} onRequestClose={()=> setModalIsOpen(false)} >
                                <Login/>
                            </Modal>

                </div>
                   
                    <div className="appbar_avt">
                        <BiMenuAltLeft onClick={handleSidebar}/>
                    </div>
            </div>
        </div>
    );
}
export default Appbar3;
